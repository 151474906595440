import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      zhTitle: '系统登录',
      enTitle: 'System Login',
      bahasaTitle: 'Log masuk sistem',
      malayTitle: 'Log masuk sistem',
      thaiTitle: 'ลงชื่อเข้าสู่ระบบ',
      vietnameseTitle: 'Đăng nhập vào hệ thống',
      burmeseTitle: 'စစ်မှန်ကြောင့် စနစ်သို့ဝင်ရန်',
      laoTitle: 'ເຂົ້າລະບົບລະບຸກຄົນລະບົບ',
      khmerTitle: 'ចូលប្រើប្រាស់ប្រព័ន្ធដែលត្រូវការ',
      filipinoTitle: 'Mag-sign in sa sistema',
      portugueseTitle: 'Fazer login no sistema'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
    meta: {
      zhTitle: '用户注册',
      enTitle: 'Registration',
      bahasaTitle: 'Pendaftaran pengguna',
      malayTitle: 'Pendaftaran Pengguna',
      thaiTitle: 'ลงทะเบียนผู้ใช้',
      vietnameseTitle: 'Đăng ký thành viên',
      burmeseTitle: 'အသုံးပြုသူမှတစ်ရင်းထည့်သွင်းခြင်း',
      laoTitle: 'ລົງທະບຽນຜູ້ໃຊ້',
      khmerTitle: 'ការចុះឈ្មោះអ្នកប្រើប្រាស់',
      filipinoTitle: 'Pagrehistro ng Tagagamit',
      portugueseTitle: 'Cadastro de Usuário'
    }
  },
  {
    path: '/companyVerify',
    name: 'companyVerify',
    component: () => import(/* webpackChunkName: "companyVerify" */ '@/views/CompanyVerify.vue'),
    meta: {
      zhTitle: '公司验证',
      enTitle: 'Company Verify',
      bahasaTitle: 'Verifikasi perusahaan',
      malayTitle: 'Pengesahan Syarikat',
      thaiTitle: 'การตรวจสอบความถูกต้องของบริษัท',
      vietnameseTitle: 'Xác thực thông tin công ty',
      burmeseTitle: 'ကုမ္ပဏီအစစ်ရေးခြင်း',
      laoTitle: 'ກວດສອບ vienthithip ຂອງusahaan',
      khmerTitle: 'ការបញ្ជាក់ពិន្ទុសហគ្រាប់មួយ',
      filipinoTitle: 'Pagpapatunay ng Kumppanya',
      portugueseTitle: 'Verificação da Empresa'
    }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import(/* webpackChunkName: "forgetPassword" */ '@/views/ForgetPassword.vue'),
    meta: {
      zhTitle: '修改密码',
      enTitle: 'Change Password',
      bahasaTitle: 'Ubah sandi',
      malayTitle: 'Tukar Kata Laluan',
      thaiTitle: 'เปลี่ยนรหัสผ่าน',
      vietnameseTitle: 'Thay đổi mật khẩu',
      burmeseTitle: 'စကားဝှက်မှတ်တမ်းကို ပြင်ဆင်ခြင်း',
      laoTitle: 'ແປ້ນລະຫັດຜ່ານໃສ່ແປ້ນໃໝ່',
      khmerTitle: 'កែប្រែពាក្យសម្ងាត់',
      filipinoTitle: 'Baguhin ang Password',
      portugueseTitle: 'Modificar a Senha'
    }
  }
]

const router = new VueRouter({
  routes
})

// 修改每个页面的 title
router.afterEach((to, from, next) => {
  const locale = sessionStorage.getItem('locale')
  const localeObj = {
    'zh-CN': to.meta.zhTitle,
    'en-US': to.meta.enTitle,
    'bahasa-Indonesia': to.meta.bahasaTitle,
    Malay: to.meta.malayTitle,
    Thai: to.meta.thaiTitle,
    Vietnamese: to.meta.vietnameseTitle,
    Burmese: to.meta.burmeseTitle,
    Lao: to.meta.laoTitle,
    Khmer: to.meta.khmerTitle,
    Filipino: to.meta.filipinoTitle,
    Portuguese: to.meta.portugueseTitle
  }
  document.title = localeObj[locale]
})

export default router
