<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import mixin from '@/mixins/mixin.js'

export default {
  name: 'App',
  mixins: [mixin],
  data() {
    return {}
  },
  created() {
    this.initLanguage()
  },
  methods: {
    // 初始化语言
    initLanguage() {
      sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : sessionStorage.setItem('locale', 'zh-CN')
      this.setCookie('localLanguage', this.$t('word1001'))
    }
  }
}
</script>

<style lang="scss">
</style>
