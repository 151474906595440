// 设置 cookie
const setCookie = (name, value) => {
  // 7天后重新登录
  const days = 7
  let date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + escape(value) + ';expires=' + date.toGMTString() + '; path=/'
}

// 获取 cookie
const getCookie = name => {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  if (arr === null) return null
  else return unescape(arr[2])
}

export { setCookie, getCookie }
