import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './language'

// 引入全局 css 样式
import '@/styles/index.scss'

// 引入 elementUI
import '@/plugins/element'

// 引入 iconfont 图标
import '@/assets/fonts/iconfont.css'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
